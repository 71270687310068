<template>
  <v-dialog v-model="open" width="500">
    <v-card class="pa-2">
      <v-card-title class="headline">{{title || $t('default_content.warning')}}</v-card-title>
      <slot name="content" />
      <v-card-text v-if="text">
        <v-layout column>
          {{text}}
          <slot name="appendContent" />
        </v-layout>
      </v-card-text>
      <v-card-actions class="pb-7 pr-7">
        <v-layout column>
          <slot name="appendAction" />
          <v-layout row justify-end v-if="!accepted && !hideActions">
            <v-btn text @click="cancel()">{{ cancelText || $t('actions.cancel') }}</v-btn>
            <v-btn outlined color="warning" @click="accept()" :disabled="disabled">{{ acceptText || $t('actions.continue') }}</v-btn>
          </v-layout>
          <v-layout row justify-end v-if="showCloseBtn">
            <v-btn @click="open = false" outlined color="primary">{{$t('actions.close')}}</v-btn>
          </v-layout>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    triggerOpen: Date,
    item: Object,
    text: String,
    title: String,
    disabled: Boolean,
    cancelText: String,
    acceptText: String,
    hideActions: Boolean,
    keepOpenAfterAccept: Boolean,
    showCloseBtn: Boolean
  },
  data () {
    return {
      open: false,
      accepted: false,
      hidden: false
    }
  },
  methods: {
    accept () {
      this.$emit('accept')
      this.accepted = true
      if (!this.keepOpenAfterAccept) {
        this.open = false
      }
    },
    cancel () {
      this.$emit('cancel')
      this.open = false
    },
    hide () { // Closes modal without triggering close event
      this.hidden = true
      this.open = false
    }
  },
  watch: {
    triggerOpen () {
      this.hidden = false
      this.open = true
    },
    open () {
      if (!this.open) {
        if (this.accepted) {
          this.$emit('closedAfterAccept')
          this.accepted = false
        }
        if (!this.hidden) {
          this.$emit('closed')
        }
      }
    }
  }
}
</script>
